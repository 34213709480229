import { CBadge, CCard, CCardBody, CCardFooter, CCardHeader, CCardTitle, CCol, CContainer, CForm, CFormInput, CFormLabel, CFormSelect, CInputGroup, CRow } from '@coreui/react';
import React, { useEffect, useState, version } from 'react';
import { DataTableComponent, DeleteModal, PrimaryButton } from '../../components';
import axios from '../../api/axios';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

const VersionControl = () => {

    const accessToken = localStorage.getItem('authToken');
    const [isLoading, setIsLoading] = useState(false);
    const [versionList, setVersionList] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [VersionId, setVersionId] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [IsDeleteBtn, setIsDeleteBtn] = useState(false);
    const [errors, setErrors] = useState({});
    const [pagination, setPagination] = useState({ page: 1, rowsPerPage: 10 });
    const [versionForm, setVersionForm] = useState({
        version_number: '',
        os_type: '',
        is_current_version: ''
    });

    useEffect(() => {
        getAllVersion();
    }, []);

    useEffect(() => {
        const result = versionList.filter((item) => {
            return item.version_number.toLowerCase().match(searchText.toLocaleLowerCase());
        });
        setFilteredData(result);
    }, [searchText])

    const columns = [
        {
            name: "Sr.No",
            // cell: (row, index) => (pagination.page - 1) * pagination.rowsPerPage + index + 1,
            cell: (row, index) => <div className="fs-7">{row.serialNumber ? row.serialNumber : "--------"}</div>,
            center: true,
        },
        {
            name: "Version Number",
            selector: (row) => row.version_number,
            cell: (row) => (
                <div className="fs-7">{row.version_number ? row.version_number : "--------"}</div>
            ),
        },
        {
            name: "OS Type",
            selector: (row) => row.os_type,
            cell: (row) => (
                <div className="fs-7">{row.os_type ? row.os_type : "--------"}</div>
            ),
        },
        {
            name: "Status",
            selector: (row) => row.is_current_version,
            cell: (row) =>
            // (
            //     <div className="fs-7">{row.is_current_version ? "Active" : "In Active"}</div>
            // ),
            (
                <div className="d-flex justify-content-center">
                    {row.is_current_version ? (
                        <CBadge color='success' className='fs-7' style={{ borderRadius: '15px' }}>Active</CBadge>
                    ) : (
                        <CBadge color='danger' className='fs-7' style={{ borderRadius: '15px' }}>Inactive</CBadge>
                    )}
                </div>
            )

        },
        {
            name: "ACTIONS",
            right: "true",
            cell: (row) => (
                <div className="d-flex justify-content-around align-items-center">
                    <a
                        href="#"
                        className="text-danger ms-2 d-flex align-items-center gap-1"
                        onClick={() => handleDeleteModal(row)}
                        style={{ textDecoration: "none" }}
                    >
                        <FaTrash /> DELETE
                    </a>
                </div>
            ),
            minWidth: "165px",
        }

    ]

    const updatedColumns = columns.map((column) => ({
        ...column,
        name: (
            <div style={{ fontSize: "13px", fontWeight: "bold" }}>{column.name}</div>
        ),
    }));

    const handleChange = (e) => {
        const { name, value } = e.target;
        // const newValue = (value === 'true' || value === 'false') ? JSON.parse(value) : value;
        const newValue = value === 'true' ? true : value === 'false' ? false : value;
        setVersionForm({
            ...versionForm,
            [name]: newValue
        });
    }

    const addVersion = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setIsLoading(true);
            try {
                const headers = {
                    Authorization: `Bearer ${accessToken}`,
                };
                const response = await axios.post("version", versionForm, { headers });
                if (response?.data?.status === true) {
                    toast.success(response?.data?.data?.message, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                    getAllVersion();
                    setVersionForm({
                        version_number: "",
                        os_type: "",
                        is_current_version: ""
                    });
                }
            } catch (error) {
                if (error?.response) {
                    let errorMessage = '';
                    if (
                        error.response?.status === 406
                    ) {
                        errorMessage = error?.response?.data?.error?.message || error?.response?.data?.error
                    }
                    else if (
                        error.response?.status === 412
                    ) {
                        errorMessage = error?.response?.data?.data?.message;
                    } else if (error.response?.status === 500) {
                        errorMessage = error?.response?.data?.error
                    }

                    toast.error(errorMessage, {
                        position: "top-right",
                        autoClose: 3000,
                        theme: "colored",
                    });
                }
            } finally {
                setIsLoading(false);
            }
        }
    }

    const getAllVersion = async () => {
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`
            }
            const response = await axios.get("version", { headers });            
            response.data?.data?.versions.forEach((item, index) => {
                item.serialNumber = index + 1;
            });
            setVersionList(response.data?.data?.versions);
            setFilteredData(response.data?.data?.versions);
        } catch (error) {
            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        }
    }

    const handleDeleteModal = async (data) => {
        setOpenDeleteModal(true);
        setVersionId(data?._id);
    }

    const handleDeleteVersion = async (e) => {
        e.preventDefault();
        setIsDeleteBtn(true);
        try {
            const headers = {
                Authorization: `Bearer ${accessToken}`,
            };
            const data = {
                _id: VersionId
            }

            const response = await axios.delete("version", { headers, data });
            if (response?.data?.status === true) {
                toast.success(response?.data?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
                getAllVersion();
                setOpenDeleteModal(false);
            }
        } catch (error) {
            if (error.response.status === 403) {
                toast.error(error?.response?.data?.message, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }

            if (error.response && error.response?.data?.status === false) {
                let errorMessage = error.response.data.error;
                toast.error(errorMessage, {
                    position: "top-right",
                    autoClose: 3000,
                    theme: "colored",
                });
            }
        } finally {
            setIsDeleteBtn(false);
        }
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    }

    const handleSearchChange = (e) => {
        setSearchText(e.target.value);
    }

    const validateForm = () => {
        const newErrors = {};
        if (!versionForm.os_type) {
            newErrors.os_type = "OS Type is required";
        }
        // if(!versionForm.is_current_version) {
        if (versionForm.is_current_version === "" || versionForm.is_current_version === null || versionForm.is_current_version === undefined) {
            newErrors.is_current_version = "Current Version Selection is required"
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }

    const handlePageChange = (page) => {
        setPagination({ ...pagination, page });
    };
      
    const handleRowsPerPageChange = (rowsPerPage) => {
        setPagination({ page: 1, rowsPerPage }); // Reset to the first page when changing rows per page
    };

    const getPaginatedData = () => {
        const startIndex = (pagination.page - 1) * pagination.rowsPerPage;
        const endIndex = startIndex + pagination.rowsPerPage;
        return filteredData?.slice(startIndex, endIndex);
    };


    return (
        <CContainer>
            <CRow>

                {/* <------------------- Add Version Form -----------------> */}
                <CCol className='col-md-4 col-lg-4'>
                    <CCard style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}>
                        <CCardHeader>
                            <CCardTitle className="fs-6 fw-semibold pt-1">
                                ADD APP VERSION
                            </CCardTitle>
                        </CCardHeader>
                        <CForm onSubmit={addVersion}>
                            <CCardBody>
                                <div className="mb-3">
                                    <CInputGroup className='flex-column'>
                                        <CFormLabel className="fw-semibold">
                                            Version Number <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormInput
                                            type="text"
                                            // min={0}
                                            placeholder="Version Number"
                                            required
                                            className="form-control w-100"
                                            name="version_number"
                                            value={versionForm.version_number}
                                            onChange={handleChange}
                                            onInput={(e) => {
                                                e.target.value = e.target.value.replace(/[^0-9.]/g, '');
                                            }}
                                            pattern="^\d+(\.\d+){0,2}$"
                                            title="Version number must be integer and must be in the format x, x.x, or x.x.x where x is a number"
                                        />
                                    </CInputGroup>
                                    <CInputGroup className='flex-column mt-2'>
                                        <CFormLabel className="fw-semibold">
                                            OS Type <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormSelect className='w-100' id="os_type" name="os_type" value={versionForm.os_type} onChange={handleChange}>
                                            <option value="" disabled >Select OS Type</option>
                                            <option value="ANDROID" >Android</option>
                                            <option value="IOS" >IOS</option>
                                        </CFormSelect>
                                        {errors.os_type && <div className='text-danger' style={{ fontSize: '12px' }}>{errors.os_type}</div>}
                                    </CInputGroup>
                                    <CInputGroup className='flex-column mt-2'>
                                        <CFormLabel className="fw-semibold">
                                            Status <span className="text-danger">*</span>
                                        </CFormLabel>
                                        <CFormSelect className='w-100' id="is_current_version" name="is_current_version" value={versionForm.is_current_version} onChange={handleChange}>
                                            <option value="" disabled >Select</option>
                                            <option value="true" >ACTIVE</option>
                                            <option value="false" >INACTIVE</option>
                                            {/* {assetType.map((asset) => {
                                    return <option key={asset._id} value={asset._id}>{asset.title}</option>  
                                    })} */}
                                        </CFormSelect>
                                        {errors.is_current_version && <div className='text-danger' style={{ fontSize: '12px' }}>{errors.is_current_version}</div>}
                                    </CInputGroup>

                                </div>
                            </CCardBody>
                            <CCardFooter>
                                <PrimaryButton title="ADD VERSION" isLoading={isLoading} />
                            </CCardFooter>
                        </CForm>
                    </CCard>
                </CCol>

                {/* <------------------- Listing Of Versions ----------------> */}
                <CCol className='col-md-8 col-lg-8'>
                    <DataTableComponent
                        title="APP VERSIONS LIST"
                        columns={updatedColumns}
                        striped
                        itemsPerPage={10}
                        // data={filteredData}
                        data={getPaginatedData()} // Use the paginated data here
                        searchText={searchText}
                        onChange={handleSearchChange}
                        pagination
                        totalRows={filteredData?.length}
                        paginationTotalRows={filteredData.length} // Total number of items
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        onChangePage={handlePageChange}
                        paginationServer={false} // Set to false for client-side pagination
                    />
                </CCol>

            </CRow>

            {/* <---------------- Delete Modal ------------------> */}
            {openDeleteModal ? (
                <DeleteModal isVisible={openDeleteModal} onClose={handleCloseDeleteModal} onConfirm={handleDeleteVersion} title="DELETE VERSION" />
            ) : null
            }

        </CContainer>
    )
}

export default VersionControl